class FingerprintSdk {
  constructor(connectFunc, disconnectFunc, acquiredFunc, qualityFunc, errorFunc) {
    this.sdk = new Fingerprint.WebApi;
    this.connectFunc = connectFunc
    this.disconnectFunc = disconnectFunc
    this.acquiredFunc = acquiredFunc
    this.qualityFunc = qualityFunc
    this.errorFunc = errorFunc
    // events
    this.sdk.onDeviceConnected = (e) => {
      this.connectFunc.call(this, e)
    }
    this.sdk.onDeviceDisconnected = (e) =>  {
      this.disconnectFunc.call(this, e)
    }
    this.sdk.onCommunicationFailed = (e) =>  {
      this.errorFunc.call(this, e)
    }
    this.sdk.onSamplesAcquired = (e) => {
      this.acquiredFunc.call(this, e)
    }
    this.sdk.onQualityReported = (e) => {
      this.qualityFunc.call(this, e)
    }
  }

  getDeviceList = function () {
    return this.sdk.enumerateDevices();
  };

  /**
   * @description Starts acquisition
   * @returns {Promise}
   */
  startCapture() {
    return this.sdk.startAcquisition(Fingerprint.SampleFormat.PngImage)
  };

  stopCapture() {
    return this.sdk.stopAcquisition()
  };

}


export default FingerprintSdk
